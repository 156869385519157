import { createTheme } from '@mui/material/styles';

export default createTheme({
  typography: {
    fontFamily: 'Nunito Sans',
  },
  palette: {
    primary: {
      main: '#113834',
    },
    secondary: {
      main: '#d28736',
    },
    background: {
      default: 'white',
    },
  },
});
