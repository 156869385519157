import qs from 'query-string';
import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ORIENTATION_SEQUENCES } from '../utils';

const DrawerNavLayout = lazy(() => import('../layouts/DrawerNav'));
const UnitLayout = lazy(() => import('../layouts/Unit'));

const Garden = lazy(() => import('../pages/Garden'));
const GardenVirtualTour = lazy(() => import('../pages/GardenVirtualTour'));
const UnitTypeA = lazy(() => import('../pages/UnitTypeA'));
const UnitTypeAVirtualTour = lazy(
  () => import('../pages/UnitTypeAVirtualTour')
);

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<DrawerNavLayout />}>
      <Route index element={<Garden />} />
      <Route element={<UnitLayout />}>
        <Route path="type-a" element={<UnitTypeA />} />
      </Route>
      <Route
        path="*"
        element={
          <Navigate
            to={{
              pathname: '/',
              search: `?${qs.stringify({
                orientation: ORIENTATION_SEQUENCES[0],
              })}`,
            }}
          />
        }
      />
    </Route>
    <Route path="/virtual-tour" element={<GardenVirtualTour />} />
    <Route path="/type-a/virtual-tour" element={<UnitTypeAVirtualTour />} />
  </Routes>
);

export default AppRoutes;
